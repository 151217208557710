@font-face {
  font-family: Exo2Light;
  src: url("/fonts/Exo2-Light.ttf");
}

%exo-light {
  font-family: Exo2Light, sans-serif;
}

@font-face {
  font-family: Exo2Bold;
  src: url("/fonts/Exo2-ExtraBold.ttf");
}

%exo-bold {
  font-family: Exo2Bold, sans-serif;
}

@font-face {
  font-family: Exo2Regular;
  src: url("/fonts/Exo2-Regular.ttf");
}

%exo-regular {
  font-family: Exo2Regular, sans-serif;
}
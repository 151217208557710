@import "../../defines";

.logo {
  position: relative;
  display: inline-block;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: 15px;
}

.logo > img {
  z-index: 1;
  position: relative;
  vertical-align: middle;
  height: 78px;
}

.logo::after, .logo::before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: $whiteColor;
  width: 70%;

  z-index: 0;
  right: 0;
  @include transform(skewX(-18deg));
}

.logo:after {
  left: 0;
  @include transform(skewX(18deg));
}

@media only screen and (max-width: 720px), only screen and (max-device-width: 720px), only screen and (max-height: 720px), only screen and (max-device-height: 720px) {

  .logo {
    display: none;
  }
}
@import "../../defines";

.footer {
  text-align: center;
  padding: 20px 10px 40px 10px;
  background-color: $blackColor;
  line-height: 1.5em;
  position: relative;
}

footer {
  color: $whiteColor;
}

footer hr {
  border-color: $whiteColor;
}

@import "../../App";

.contactUsBackground {
  background-color: transparent;
}

.map {
  height: 400px;
}

.map > iframe {
  width: 100%;
  height: 700px;
  border: none;
  top: 0;
  left: 0;
  position: fixed;
}

.appResponsive.contactsTable {
  grid-row-gap: 30px;
}

@media only screen and (max-width: 720px), only screen and (max-device-width: 720px),
only screen and (max-height: 720px), only screen and (max-device-height: 720px) {
  .map > iframe {
    height: 600px;
  }
}
$whiteColor: #f2f2f2;
$whiteOp0p75Color: rgba(242, 242, 242, 0.75);
$whiteOp0p85Color: rgba(242, 242, 242, 0.85);
$whiteOp0p9Color: rgba(242, 242, 242, 0.9);

$darkWhiteColor: #d2d2d2;

$linGradWhite1: #e2e2e2;
$linGradWhite2: $whiteColor;

$blackColor: #262626;
$blackOp0p75Color: rgba(38, 38, 38, 0.75);
$blackOp0p9Color: rgba(38, 38, 38, 0.9);

$blueColor: #00aeef;
$blueOp0p6Color: rgba(0, 174, 239, 0.6);
$blueOp0p75Color: rgba(0, 174, 239, 0.75);

$orangeColor: #fc8230;
$orangeOp0p9Color: rgba(252, 130, 48, 0.9);

$darkGrayColor: #323232;

$grayColor: #4a5459;
$grayOp0p3Color: rgba(74, 84, 89, 0.3);
$grayOp0p75Color: rgba(74, 84, 89, 0.75);
$grayOp0p9Color: rgba(74, 84, 89, 0.9);

$linGradGray1: #3a4449;
$linGradGray2: $grayColor;

@mixin backface-visibility($prop) {
  backface-visibility: $prop;
  -webkit-backface-visibility: $prop;
  -moz-backface-visibility: $prop;
}

@mixin transform-style($prop) {
  transform-style: $prop;
  -webkit-transform-style: $prop;
}

@mixin perspective($prop) {
  perspective: $prop;
  -webkit-perspective: $prop;
  -moz-perspective: $prop;
}

@mixin writing-mode($prop) {
  writing-mode: $prop;
  -webkit-writing-mode: $prop;
}

@mixin box-sizing($prop) {
  box-sizing: $prop;
  -webkit-box-sizing: $prop;
  -moz-box-sizing: $prop;
}

@mixin align-items($prop) {
  align-items: $prop;
  -webkit-align-items: $prop;
}

@mixin transform-origin($prop) {
  transform-origin: $prop;
  -webkit-transform-origin: $prop;
  -moz-transform-origin: $prop;
  -o-transform-origin: $prop;
}

@mixin transition-duration($prop) {
  transition-duration: $prop;
  -webkit-transition-duration: $prop;
  -moz-transition-duration: $prop;
  -o-transition-duration: $prop;
}

@mixin transition-delay($prop) {
  transition-delay: $prop;
  -webkit-transition-delay: $prop;
  -moz-transition-delay: $prop;
  -o-transition-delay: $prop;
}

@mixin box-shadow($prop) {
  box-shadow: $prop;
  -webkit-box-shadow: $prop;
  -moz-box-shadow: $prop;
}

@mixin border-radius($prop) {
  border-radius: $prop;
  -webkit-border-radius: $prop;
  -moz-border-radius: $prop;
}

@mixin transition($prop...) {
  transition: $prop;
  -webkit-transition: $prop;
  -moz-transition: $prop;
  -o-transition: $prop;
}

@mixin transform($prop) {
  transform: $prop;
  -webkit-transform: $prop;
  -moz-transform: $prop;
  -o-transform: $prop;
}

@mixin animation-name($prop) {
  animation-name: $prop;
  -webkit-animation-name: $prop;
  -moz-animation-name: $prop;
  -o-animation-name: $prop;
}

@mixin animation-timing-function($prop) {
  animation-timing-function: $prop;
  -webkit-animation-timing-function: $prop;
  -moz-animation-timing-function: $prop;
  -o-animation-timing-function: $prop;
}

@mixin animation-iteration-count($prop) {
  animation-iteration-count: $prop;
  -o-animation-iteration-count: $prop;
  -moz-animation-iteration-count: $prop;
  -webkit-animation-iteration-count: $prop;
}

@mixin animation-duration($prop) {
  animation-duration: $prop;
  -webkit-animation-duration: $prop;
  -moz-animation-duration: $prop;
  -o-animation-duration: $prop;
}

@mixin animation-play-state($prop) {
  animation-play-state: $prop;
  -webkit-animation-play-state: $prop;
  -moz-animation-play-state: $prop;
  -o-animation-play-state: $prop;

}

@mixin stock-background($prop) {
  background: url($prop) top center / cover no-repeat fixed;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin blur-filter($prop) {
  filter: blur($prop);
  -webkit-filter: blur($prop);
}
@import "PopUp";

.confirmPopUpContent {
  @extend %popUpContent;
  padding: 20px 10px 10px;
  max-width: 300px;
  margin: auto;
  overflow-x: auto;
  background-image: linear-gradient(15deg, $linGradWhite1 50%, $linGradWhite2 50%);
  background-image: -webkit-linear-gradient(15deg, $linGradWhite1 50%, $linGradWhite2 50%);
  background-image: -moz-linear-gradient(15deg, $linGradWhite1 50%, $linGradWhite2 50%);
}
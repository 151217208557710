@import "../../defines";

.productBox {
  display: grid;
  grid-template-areas: "productCoverImage productInformation" "productCoverImage productCart" "productDescription productDescription";
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto auto;
}

.productImageContent {
  grid-area: productCoverImage;
  position: relative;
}

.productImageListContent {
  position: absolute;
  left: 2%;
  right: 2%;
  bottom: 0;
}

.productImageList {
  width: -webkit-calc(100% - 10px);
  width: -moz-calc(100% - 10px);
  width: calc(100% - 10px);
  overflow-x: auto;
  padding: 5px;
  opacity: 0.3;
  display: flex;
  margin: auto;
  max-width: 400px;
  @include transition-duration(.2s);
  background-color: $blackOp0p75Color;
  @include border-radius(5px);
  @include box-shadow(0px 0px 4px 0px $blackOp0p75Color);
}

.productImageList:hover {
  opacity: 1;
}

.productImageList > div {
  margin: 5px;
  max-height: 60px;
  max-width: 90px;
  width: 100%;
}

.productImageList img {
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  width: auto;
  margin: 0;
  @include border-radius(5px);
  @include box-shadow(1px 1px 5px 0 $blackOp0p75Color);
}

.productCartContent {
  grid-area: productCart;
}

.productDescriptionContent {
  grid-area: productDescription;
}

.productInformationContent {
  grid-area: productInformation;
}

.productInformation > div {
  margin: auto auto auto 0;
}

.productInformation {
  display: grid;
  grid-template-columns: 50% 50%;
}

.productData {
  margin-right: 5px;
  margin-left: 5px;
}

.productDataTitle {
  text-align: left;
  padding: 5px 2% 5px;
  margin-top: 15px;
  @include border-radius(5px);
}

.productDataContent {
  margin-left: 5px;
  margin-right: 5px;
  padding: 10px 15px;
}

.productCoverImageContent {
  text-align: center;
}

.productCoverImage {
  max-width: 400px;
  max-height: 400px;
  margin: 10%;
  width: auto;
  @include border-radius(10px);
  @include box-shadow(1px 1px 10px 0 $blackOp0p75Color);
}

.productAddToCartBox {
  display: grid;
  grid-template-columns: auto auto;
}

.productAddToCartBox > div {
  margin: auto;
}

@media only screen and (max-width: 720px), only screen and (max-device-width: 720px),
only screen and (max-height: 720px), only screen and (max-device-height: 720px) {
  .productBox {
    grid-template-areas: "productCoverImage" "productInformation" "productCart" "productDescription";
    grid-template-rows: auto;
    grid-template-columns: 100%;
  }

  .productCoverImage{
    max-width: 220px;
  }
}


@import "../../defines";

.summaryCartItem {
  margin: 5px;
}

.summaryCartItemName {
  padding: 2px 5px 2px;
  text-align: left;
  @include border-radius(3px);
}

.summaryCartItemPiece, .summaryCartItemUnitPrice, .summaryCartItemSumPrice {
  margin: auto;
}

.summaryCartItemPiece {
  grid-area: orderPiece;
  margin-left: 5px;
}

.summaryCartItemUnitPrice {
  grid-area: orderUnit;
  margin-right: 0;
}

.summaryCartItemSumPrice {
  grid-area: orderSum;
  margin-right: 5px;
}

.summaryCartItemContent {
  display: grid;
  grid-template-areas: "orderPiece orderUnit orderSum";
  grid-template-columns: 33% 34% 33%;
  margin-left: 3px;
  margin-right: 3px;
  padding: 10px 5px 10px 5px;
  @include transition-duration(.2s);
}

.summaryCartItem:hover .summaryCartItemContent {
  background-color: $darkWhiteColor;
}

.summaryCartListTitle {
  @extend .summaryCartItemContent;
  padding: 5px;
}
@import "../defines";

.popUp {
  position: fixed;
  top: -2000px;
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: 30;
  display: grid;
  left: 0;

  @include transition(all 0.5s ease-in-out);
}

.popUp.visible {
  opacity: 1;
  top: 0;
}

%popUpContent {
  @include box-shadow(4px 4px 20px $blackColor);
  @include border-radius(15px);
}

.popUpContent {
  @extend %popUpContent;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-x: auto;
  background-image: linear-gradient(15deg, $linGradWhite1 50%, $linGradWhite2 50%);
  background-image: -webkit-linear-gradient(15deg, $linGradWhite1 50%, $linGradWhite2 50%);
  background-image: -moz-linear-gradient(15deg, $linGradWhite1 50%, $linGradWhite2 50%);
}

.popUpFrame {
  margin: 30px;
  position: relative;

  width: calc(100% - 60px);
  width: -webkit-calc(100% - 60px);
  width: -moz-calc(100% - 60px);

  height: calc(100% - 60px);
  height: -webkit-calc(100% - 60px);
  height: -moz-calc(100% - 60px);

  display: grid;
  text-align: center;
}

.popUp::before {
  content: '';
  position: absolute;
  background-color: $blackOp0p75Color;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
}

.popUpClose {
  position: absolute;
  z-index: 1;
  top: -36px;
  right: -36px;
}

.popupTitle {
  padding: 5px 3%;
  margin-left: 5px;
  margin-right: 5px;
}

.popUpPadding {
  padding: 15px;
}
@import "../../defines";

.noticeBlock {
  bottom: -2000px;
  z-index: 50;
  position: fixed;
  left: 0;
  max-width: 450px;
  display: flex;
  @include transition(all 0.5s ease-in-out);
  opacity: 0;
}

.noticeBlockContent {
  width: 100%;
  margin: 10px;
  position: relative;
  @include box-shadow(0 0 5px $blackOp0p75Color);
  @include border-radius(0 0 3px 3px);
}

.noticeBlockData {
  color: $whiteColor;
  text-align: center;
  overflow-y: auto;
  display: grid;
  grid-row-gap: 1px;
  margin-top: 1px;
  margin-bottom: 1px;
}

.noticeData {
  padding: 5px 20px;
  background-color: $orangeOp0p9Color;
}

.noticeBlock.visible {
  opacity: 1;
  bottom: 0;
}

.noticeCloseButton {
  top: -36px;
  right: -36px;
  z-index: 2;
  position: absolute;
  visibility: hidden;
}

.noticeCloseButton.visible {
  visibility: inherit;
}

.noticeTop, .noticeBottom {
  width: 100%;
  height: 20px;
  background-color: $orangeOp0p9Color;
  @include border-radius(3px 3px 0 0);
}

.noticeTop > .noticeTimeLine {
  height: 4px;
  background-color: $whiteColor;
  width: 100%;
  margin: 0 0 0 auto;
  @include border-radius(3px 3px 0 3px);
}

.noticeTop > .noticeTimeLine.startNoticeTimeLine {
  @include transition(width .5s linear);
}

.noticeBottom {
  @include border-radius(0 0 3px 3px);
}

@media only screen and (max-width: 720px), only screen and (max-device-width: 720px), only screen and (max-height: 720px), only screen and (max-device-height: 720px) {
  .noticeBlock {
    left: 20px;
    right: 20px;
  }
}
@import "../defines";

@keyframes inputCheckerWarningPulsation {
  50% {
    border-bottom: 3px solid $blueColor;
  }
}

@-webkit-keyframes inputCheckerWarningPulsation {
  50% {
    border-bottom: 3px solid $blueColor;
  }
}

@-moz-keyframes inputCheckerWarningPulsation {
  50% {
    border-bottom: 3px solid $blueColor;
  }
}

@-o-keyframes inputCheckerWarningPulsation {
  50% {
    border-bottom: 3px solid $blueColor;
  }
}

.inputCheckerWarning {
  @include animation-name(inputCheckerWarningPulsation);
  @include animation-timing-function(ease-in-out);
  @include animation-iteration-count(infinite);
  @include animation-duration(1.5s);
  @include animation-play-state(running !important);
}
@import "../../../defines";

.activationImageFlipCard {
  width: 100%;
  max-width: 400px;
  height: 400px;
  position: relative;
  margin: auto;
  @include perspective(800px);
  @include transform-style(preserve-3d);
  @include transition-duration(0.3s);
}

.activationImageFlipCard .activationImageBack {
  @include transform(rotateY(180deg));
}

.activationImageFlipCard.visible {
  @include transform(rotateY(180deg));
}

.activationImageFlipCard figure {
  margin: 0;
  position: absolute;
  width: 100%;
  @include backface-visibility(hidden);
}

.activationImageFlipCard figure img {
  width: 100%;
  height: 100%;
  margin: 0;
}
@import "../../defines";

.cartItem {
  margin: 5px;
}

.cartItemName {
  padding: 2px 5px 2px;
  text-align: left;
  @include border-radius(3px);
}

.cartItemPiece, .cartItemUnitPrice, .cartItemSumPrice {
  margin: auto;
}

.cartItemPiece {
  grid-area: piece;
  margin-left: 5px;
}

.cartItemUnitPrice {
  grid-area: unit;
  margin-right: 0;
}

.cartItemSumPrice {
  grid-area: sum;
  margin-right: 5px;
}

.cartItemTools {
  grid-area: tools;
  margin: auto 0 auto auto;
}

.cartItemContent {
  display: grid;
  grid-template-areas: "piece unit sum tools";
  grid-template-columns: 25% 25% 25% auto;
  margin-left: 3px;
  margin-right: 3px;
}

@keyframes cartItemDontSavePulsation {
  50% {
    background-color: $darkWhiteColor;
  }
}

@-webkit-keyframes cartItemDontSavePulsation {
  50% {
    background-color: $darkWhiteColor;
  }
}

@-moz-keyframes cartItemDontSavePulsation {
  50% {
    background-color: $darkWhiteColor;
  }
}

@-o-keyframes cartItemDontSavePulsation {
  50% {
    background-color: $darkWhiteColor;
  }
}

.cartItemDontSave {
  @include animation-name(cartItemDontSavePulsation);
  @include animation-timing-function(ease-in-out);
  @include animation-iteration-count(infinite);
  @include animation-duration(1.5s);
  @include animation-play-state(running !important);
}

@media only screen and (max-width: 720px), only screen and (max-device-width: 720px), only screen and (max-height: 720px), only screen and (max-device-height: 720px) {

  .cartItemContent {
    grid-template-areas: "piece unit sum" "tools tools tools";
    grid-template-columns: auto;
  }

  .cartItemTools {
    margin: auto;
  }

}

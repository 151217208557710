@import "../../defines";

.menuBar {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  text-align: left;
}

.menuBar ul {
  z-index: 10;
  text-align: center;
  line-height: 2;
  font-weight: bold;
  font-size: large;
  display: grid;
  grid-auto-flow: column;
  margin: auto auto auto 25px;
  padding-left: 15px;
  padding-right: 15px;

}

.menuBar li {
  list-style: none;
  position: relative;
  display: block;
  @include border-radius(inherit);
  padding: 0;
  margin: 5px 10px 5px 10px;
}

.menuBar li a {
  text-transform: uppercase;
  display: block;
  color: $whiteColor;
  padding: 5px 15px 5px;
  text-decoration: none;
  @include transition-duration(0.3s);
}

.menuBar li a::after, .menuBar li a::before, .menuBar .highlight a::before,
.menuBar .highlight a::after {
  content: '';
  position: absolute;

  height: 100%;
  width: 0;
  z-index: -1;
  bottom: 0;
  right: 0;
  @include transition-duration(0.3s);
  @include transform(skewX(-18deg));
}

.menuBar li a::after, .menuBar .highlight a::after {
  left: 0;
  @include transform(skewX(18deg));
}

.menuBar .highlight a::before,
.menuBar .highlight a::after, .menuBar li a:hover::after, .menuBar li a:hover::before {
  background-color: $blueColor;
  width: 70%;
}

.menuButton, .menuIcon, .menuIconsBar {
  display: none;
}

@media only screen and (max-width: 720px), only screen and (max-device-width: 720px), only screen and (max-height: 720px), only screen and (max-device-height: 720px) {
  .menuBar {
    display: inherit;
  }

  .menuBar ul {

    margin: 18px 0;
    max-height: 80vh;
    overflow-y: auto;

    display: block;
    position: absolute;
    left: -415px;
    top: 40px;
    background-color: $darkGrayColor;

    @include transform(rotateY(90deg));
    @include transition(0.3s);
    @include transform-origin(0 0);
    @include box-shadow(0 0 10px 2px $darkGrayColor);
    @include border-radius(0 0 10px 0);

    opacity: 0;
  }

  .menuButtonEvent + ul {
    left: 0;
    @include transform(rotateY(0deg));

    opacity: 1;
  }

  .menuButton {
    cursor: pointer;
    margin-left: 5px;
    display: inline-flex;
    @include border-radius(3px);
    @include transition-duration(0.3s);
  }

  .menuIconsBar {
    display: block;
    height: 2px;
    background-color: $whiteColor;
    margin: auto;

    @include box-shadow(0 0 1px $whiteColor);
    @include transition-duration(0.5s);

  }

  .menuButtonEvent {
    background-color: $blueColor;
  }

  .menuIcon {
    width: 40px;
    display: inline-block;
    padding: 10px 5px;
  }

  .menuIcon {
    .menuIconsBar:nth-of-type(2) {
      margin-top: 11px;
      margin-bottom: 11px;
    }
  }

  .menuButtonEvent {
    .menuIconsBar {
      background-color: $whiteColor;
    }
  }

  .menuButton:hover:not(.menuButtonEvent) .menuIcon {
    .menuIconsBar:nth-of-type(1) {
      @include transform(translate(0px, 3px) rotateZ(10deg));
    }
  }

  .menuButtonEvent {
    .menuIconsBar:nth-of-type(1) {
      @include transform(translate(0px, 13px) rotateZ(45deg));
    }
  }

  .menuButton:hover:not(.menuButtonEvent) {
    .menuIconsBar:nth-of-type(2) {
      @include transform(rotateZ(10deg));
    }
  }

  .menuButtonEvent {
    .menuIconsBar:nth-of-type(2) {
      opacity: 0;
      @include transform(rotateZ(10deg));
    }
  }

  .menuButton:hover:not(.menuButtonEvent) {
    .menuIconsBar:nth-of-type(3) {
      @include transform(translate(0px, -3px) rotateZ(-10deg));
    }
  }

  .menuButtonEvent {
    .menuIconsBar:nth-of-type(3) {
      @include transform(translate(0px, -13px) rotateZ(-45deg));
    }
  }
}
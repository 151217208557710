@import "../../defines";

.billingDataContent {
  position: relative;
  @include transition-duration(.2s);
  @include blur-filter(10px);
}

.billingDataContent::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.billingDataContent.visible::before {
  z-index: -1;
}

.billingDataContent.visible {
  @include blur-filter(0);
}

.billingDataQuestionContent {
  text-align: center;
}

.billingDataQuestionTitle {
  vertical-align: middle;
}
.cartSumPriceContent {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: auto auto;
}

.totalPriceTitle {
  text-align: left;
}

.totalPrice {
  text-align: right;
}
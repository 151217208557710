@import "../../defines";

.shopItemsList {
  text-align: center;
  @include box-sizing(border-box);
}

.shopItem {
  position: relative;
  display: inline-table;
  margin: 10px 25px 100px;
  width: 100%;
  max-width: 300px;
  @include perspective(800px);
}

.shopItem a {
  text-decoration: none;
}

.shopItemImage {
  display: grid;

  margin: 0;
  @include border-radius(50%);
  height: 300px;
  width: 100%;
  opacity: 0.8;
  @include transition-duration(0.3s);
}

.shopItemName {
  padding: 2% 20px 2%;

  width: calc(100% - 40px);
  width: -webkit-calc(100% - 40px);
  width: -moz-calc(100% - 40px);
  @include transition-duration(0.3s);
}

.shopItemContent {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  display: initial;
  @include transform-style(preserve-3d);
  @include transform(rotateX(90deg));
  @include backface-visibility(hidden);
  @include transition-duration(0.15s);
  @include transition-delay(0.15s);

  height: 100%;
  text-overflow: clip;
  opacity: 0;
}

.shopItem:hover .shopItemContent {
  @include transform(rotateX(0deg));
  opacity: 1;
}

.shopItemDescriptionContent {
  text-align: justify;
  overflow-x: auto;
  @include border-radius(10px 10px 0 0);
  padding: 0;
  width: 100%;
  height: calc(100% - 100px);
  height: -webkit-calc(100% - 100px);
  height: -moz-calc(100% - 100px);
}

.shopItemDescriptionContent > div {
  padding: 10px;
}

.shopItemDescriptionContent.shopItemGroup {
  height: 100%;
}

.shopItemPrice {
  height: 80px;
  padding: 10px;
  display: grid;
  grid-template-areas: "shopItemPriceTitle shopItemPriceValue" "shopItemCart shopItemCart";
  grid-template-columns: 50% 50%;
  grid-template-rows: 20px 60px;
}

.shopItemPriceTitleContent {
  grid-area: shopItemPriceTitle;
}

.shopItemPriceValueContent {
  grid-area: shopItemPriceValue;
}

.shopItemCartContent {
  grid-area: shopItemCart;
}

.shopItemFlip {
  position: relative;
  @include perspective(800px);
}

.shopItemFlipCard {
  width: 100%;
  height: 100%;
  position: absolute;
  @include transform-style(preserve-3d);
  @include transition-duration(0.3s);
}

.shopItemFlipCard .shopItemBack {
  @include border-radius(0 0 30px 30px);
  @include box-shadow(1px 1px 10px 0 $blackColor);
  @include transform(rotateY(180deg));
}

.shopItem:hover .shopItemFlipCard {
  @include transform(rotateY(180deg));
}

.shopItemFlipCard figure {
  margin: 0;
  position: absolute;
  @include backface-visibility(hidden);
}

.shopItem:hover .shopItemImage {
  @include border-radius(10px 10px 0 0);
  @include box-shadow(1px 1px 10px 0 $blackColor);
  opacity: 1;
}

@media only screen and (max-width: 720px), only screen and (max-device-width: 720px),
only screen and (max-height: 720px), only screen and (max-device-height: 720px) {
  .shopItem {
    width: calc(100% - 60px);
    width: -webkit-calc(100% - 60px);
    width: -moz-calc(100% - 60px);
    margin: 10px 10px 70px;
  }

  .shopItemImage {
    width: 80vw;
    height: 80vw;
  }
}
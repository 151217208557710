@import "../defines";

@keyframes loadingAnimation {
  from {
    @include transform(rotate(0deg));
  }

  to {
    @include transform(rotate(360deg));
  }
}

@-webkit-keyframes loadingAnimation {
  from {
    @include transform(rotate(0deg));
  }

  to {
    @include transform(rotate(360deg));
  }
}

@-moz-keyframes loadingAnimation {
  from {
    @include transform(rotate(0deg));
  }

  to {
    @include transform(rotate(360deg));
  }
}

@-o-keyframes loadingAnimation {
  from {
    @include transform(rotate(0deg));
  }

  to {
    @include transform(rotate(360deg));
  }
}

.loadingImg {
  @include animation-name(loadingAnimation);
  @include animation-timing-function(ease-in-out);
  @include animation-iteration-count(infinite);
  @include animation-duration(1s);
  @include animation-play-state(running !important);

  height: 50px;
}

.loadingContent {
  padding: 10px;
  text-align: center;
}

.loadingContent > * {
  vertical-align: middle;
  padding: 5px;
  margin: auto;
}

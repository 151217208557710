@import "../../defines";

.cartBlock {
  position: fixed;
  top: 120px;
  right: -500px;
  width: 500px;
  z-index: 25;
  @include transition-duration(0.3s);
}

.cartBlock hr {
  width: -webkit-calc(100% - 5px);
  width: -moz-calc(100% - 5px);
  width: calc(100% - 5px);
}

.cartBlockContent {
  padding: 10px 0;
  text-align: center;

  @include box-shadow(0 0 10px 0px $blackOp0p75Color);
  @include border-radius(5px 0 0 5px);
  @include transition-duration(0.3s);

  opacity: 0;
}

.cartBlock.visible {
  right: 0;
}

.cartBlock.visible .cartBlockContent {
  opacity: 1;
}

.cartButton {
  position: absolute;
  left: -58px;
  top: -29px;
  z-index: 10;
}

.cartBlock.visible > .cartButton {
  left: -29px;
}

@media only screen and (max-width: 720px), only screen and (max-device-width: 720px), only screen and (max-height: 720px), only screen and (max-device-height: 720px) {
  .cartBlock.visible {
    width: -webkit-calc(100% - 29px);
    width: -moz-calc(100% - 29px);
    width: calc(100% - 29px);
  }
}

@import "../defines.scss";

.APIProgressBar {
  position: fixed;
  width: 100%;
  z-index: 99;
}

.APIProgressBar hr {
  margin: 0;
  opacity: 1;
  width: 0;
  border-bottom: 3px solid $orangeOp0p9Color;
  @include box-shadow(1px 1px 2px 0 $orangeOp0p9Color);
}

hr.APIProgressBarTransition {
  @include transition(width .5s linear, opacity 1s ease-out .5s);
}

hr.APIProgressBarTransitionEnd {
  opacity: 0;
  width: 100%;
}
@import "../../defines";

.summaryTitle {
  padding: 10px;
  margin-top: 15px;
  @include border-radius(5px);
}

.summaryItem {
  text-align: left;
  padding: 0 5px 0;
}

.summaryItem .summaryItemTitle {
  margin: 15px auto 0 auto;
}

.summaryContent {
  margin-left: 5px;
  margin-right: 5px;
}

.summaryPaymentContent {
  display: grid;
  grid-template-columns: auto auto;
}

.summaryPaymentContentLine {
  grid-column: 1/3;
}

.summaryTotalOrderPriceTitle, .summaryPaymentMethodName {
  text-align: left;
}

.summaryTotalOrderPrice, .summaryShippingPrice {
  text-align: right;
}

.summaryGuest {
  margin-top: 20px;
}
@import "defines";
@import "Font";

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/materialicons/v28/2fcrYFNaTjcS6g4U3t-Y5ZjZjT5FdEJ140U2DJYC3mY.woff2") format('woff2');
}

.materialIcons {
  font-family: 'Material Icons', serif;
  font-weight: normal;
  font-style: normal;
  font-size: 2em;
  line-height: 1em;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

html {
  background-color: $grayColor;
}

body {
  @extend %exo-regular;
  margin: auto;
  color: $whiteColor;
  overflow-x: hidden;
}

header {
  z-index: 25;
  background-color: $darkGrayColor;
  @include box-shadow(0 0 10px 2px $darkGrayColor);
  position: fixed;
  width: 100%;
  grid-template-columns: auto auto !important;
}

a {
  color: $blueColor;
}

.c1 {
  font-size: 1em;
}

.c1p5 {
  font-size: 1.5em;
}

.c2 {
  font-size: 2em;
}

.c3 {
  font-size: 3em;
}

.c4 {
  font-size: 4em;
}

.c5 {
  font-size: 5em;
}

::placeholder {
  color: $blackOp0p75Color;
  font-family: Exo2Light, sans-serif;
}

::-webkit-input-placeholder {
  color: $blackOp0p75Color;
  font-family: Exo2Light, sans-serif;
}

::-moz-placeholder {
  color: $blackOp0p75Color;
  font-family: Exo2Light, sans-serif;
}

:-ms-input-placeholder {
  color: $blackOp0p75Color;
  font-family: Exo2Light, sans-serif;
}

:-moz-placeholder {
  color: $blackOp0p75Color;
  font-family: Exo2Light, sans-serif;
}

hr {
  margin: 5px auto 5px auto;
  border-bottom: 2px solid;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 100%;
}

.appToolbar, .appToolbarItem {
  vertical-align: middle;
}

.appButton, .appCircleButton {
  cursor: pointer;
  text-decoration: none;
  color: $whiteColor;
  background-color: transparent;
  border: none;
  margin: 10px;
  text-align: center;
  position: relative;
  z-index: 0;
  padding: 10px 30px;
  display: inline-block;
}

.appButton {
  background-color: $blueOp0p6Color;
  @include transition-duration(0.3s);
  @include border-radius(5px);
  @extend %exo-regular;
}

.appButton:hover {
  background-color: $blueColor;
}

.appCircleButton {
  padding: 10px;

  @include transition-duration(0.3s);
  @include box-shadow(1px 1px 3px $blackColor);
  @include border-radius(50%);

  background-color: $blueOp0p6Color;
  display: inline-grid;
}

.appCircleButton:hover {
  background-color: $blueColor;
}

.appCircleButton.appImgButton {
  margin: 5px;
  padding: 0;
  background-color: transparent;
}

.appCircleButton.appImgButton img {
  @include border-radius(50%);
  @include transition-duration(0.3s);

  width: 3em;
  margin: 0;
}

.appCircleButton.appImgButton:hover {
  @include transform(scale(1.3));
}

.appCircleButton.appImgButton:hover img {
  background-color: transparent;
}

.appInput {
  @extend %exo-light;
  color: $blackColor;
  background-color: $whiteColor;
  border-bottom: 3px solid $blackColor;
  border-right: none;
  border-top: none;
  border-left: none;

  width: calc(100% - 40px);
  width: -webkit-calc(100% - 40px);
  width: -moz-calc(100% - 40px);

  padding: 10px;
  margin: 10px auto;
  display: inherit;
  @include border-radius(4px 4px 0 0);
}

.appInputTitle {
  text-align: left;
  padding: 10px 10px 0 10px;
  margin: 10px auto auto;
}

.appCheckboxBlock {
  display: inline-block;
  vertical-align: middle;
}

.appCheckbox {
  padding: 0;
  @include border-radius(5px);
  @include box-shadow(none);
}

input, textarea, select, .appInputTitle {
  max-width: 500px;
}

input[type="number"] {
  max-width: 3em;
  display: inline-block;
}

input[type="file"], input[type="checkbox"] {
  display: none;
}

textarea {
  height: 250px;
  resize: none;
}

.appText {
  line-height: 1.8;
  text-align: justify;
}

.appResponsive {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: center;
}

.appResponsive.r3 {
  grid-template-columns: 33% 33% 33%;
  grid-column-gap: 0.5%;
}

.appResponsive.r4 {
  grid-template-columns: 25% 25% 25% 25%;
}

.appResponsive.verticalTop > * {
  margin-top: 0;
}

.appResponsive > * {
  margin: auto 0;
}

@media only screen and (max-width: 720px), only screen and (max-device-width: 720px),
only screen and (max-height: 720px), only screen and (max-device-height: 720px) {

  header {
    grid-template-columns: 65px auto !important;
  }

  .c1p5 {
    font-size: 1.1em;
  }

  .c2 {
    font-size: 1.5em;
  }

  .c3 {
    font-size: 2em;
  }

  .c4 {
    font-size: 2.75em;
  }

  .c5 {
    font-size: 3.75em;
  }

  .appResponsive, .appResponsive.r3, .appResponsive.r4 {
    grid-template-columns: 100%;
  }
}
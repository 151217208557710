@import "../../defines";
@import "CartItem";

.cartContent hr {
  width: -webkit-calc(100% - 5px);
  width: -moz-calc(100% - 5px);
  width: calc(100% - 5px);
}

.cartList {
  max-height: 400px;
  overflow-x: auto;
}

.cartListTitle {
  @extend .cartItemContent;
  grid-template-areas: "piece unit sum";
}

@media only screen and (max-width: 720px), only screen and (max-device-width: 720px), only screen and (max-height: 720px), only screen and (max-device-height: 720px) {

  .cartList {
    max-height: 250px;
    overflow-x: auto;
  }
}

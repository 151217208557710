@import "../../defines";

.slideShowItem {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  bottom: 0;
}

.slideShowContainer {
  width: 100%;
  height: 560px;
}

.slideShowBackground {
  @include blur-filter(5px);
}

.slideShowBackgroundFirst {
  @include stock-background("../../images/home/mcu-pcb-background.jpg");
}

.slideShowBackgroundSecond {
  @include stock-background("../../images/home/brake-light-background.jpg");
}

.slideShowBackground::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $whiteOp0p75Color;
}

@media only screen and (max-width: 720px), only screen and (max-device-width: 720px),
only screen and (max-height: 720px), only screen and (max-device-height: 720px) {
  .slideShowContainer {
    height: 700px;
  }
}
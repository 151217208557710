@import "../defines";

.cookieBar {
  position: fixed;
  bottom: -500px;
  left: 5%;
  right: 5%;

  @include align-items(center);

  display: grid;
  grid-template-columns: auto 200px;
  padding: 1%;
  z-index: 15;
  opacity: 0;

  background-color: $blackColor;
  @include border-radius(6px);
  @include transition-duration(0.3s);
}

.cookieBar.visible {
  opacity: 1;
  bottom: 5px;
}

.cookieBarDescription {
  padding-left: 20px;
}

.cookieBarLearnMore {
  display: block;
  margin: 10px 0 10px;
}

@media only screen and (max-width: 720px), only screen and (max-device-width: 720px), only screen and (max-height: 720px), only screen and (max-device-height: 720px) {

  .cookieBarDescription {
    padding: 0;
    text-align: justify;
  }

  .cookieBar {
    text-align: center;
    grid-template-columns: auto;
    padding: 4%;

  }
}
@import "../defines";

section {
  padding: 150px 4% 150px;
  position: relative;
  @include box-sizing(border-box);
}

.stock {
  margin: auto;
  max-width: 1200px;
  position: relative;
  z-index: 2;
}

section.skewLeftTop, section.skewRightTop {
  padding-top: 75px;
}

section.skewLeftBottom, section.skewRightBottom {
  padding-bottom: 75px;
}

section.skewRightTop::before, section.skewLeftTop::before,
section.skewRightBottom::after, section.skewLeftBottom::after {
  content: ' ';
  position: absolute;
  top: -50px;
  left: 0;
  display: block;
  height: 100px;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}

section.skewRightBottom::after, section.skewLeftBottom::after {
  top: auto;
  bottom: -50px;
}

section.skewRightTop::before, section.skewRightBottom::after {
  @include transform(skewY(-2deg) translateZ(0));
}

section.skewLeftTop::before, section.skewLeftBottom::after {
  @include transform(skewY(2deg) translateZ(0));
}

section .stockImg {
  width: 80%;
  max-width: 400px;
  @include box-shadow(1px 1px 10px $blackColor);
  @include border-radius(50%);
  margin: 5%;
}

.blue, section.blue::after, section.blue::before {
  background-color: $blueColor;
  color: $whiteColor;
}

.blue hr {
  border-color: $whiteColor;
}

.black, section.black::after, section.black::before {
  background-color: $grayColor;
  color: $whiteColor;
}

.black hr {
  border-color: $whiteColor;
}

.white, section.white::after, section.white::before {
  background-color: $whiteColor;
  color: $grayColor;
}

.white hr {
  border-color: $grayColor;
}

section .preface {
  margin-top: 1%;
  margin-bottom: 1%;
  text-align: center;
}

.emphasize {
  @include border-radius(30px);
  padding: 2% 4%;
  display: inline-grid;
}

.emphasize.blue {
  background-color: $blueOp0p75Color;
}

.emphasize.white {
  background-color: $whiteOp0p75Color;
}

.emphasize.black {
  background-color: $blackOp0p75Color;
}

.backTextLeft {
  position: absolute;
  left: 0;
  @include writing-mode(vertical-rl);
  opacity: 0.3;
  line-height: 1.1em;
  font-size: 14em;
  font-weight: 900;
  z-index: 1;
}

@media only screen and (max-width: 720px), only screen and (max-device-width: 720px),
only screen and (max-height: 720px), only screen and (max-device-height: 720px) {

  section {
    padding: 100px 4% 100px;
  }

  section.skewLeftTop, section.skewRightTop {
    padding-top: 50px;
  }

  section.skewLeftBottom, section.skewRightBottom {
    padding-bottom: 50px;
  }

  .emphasize {
    @include border-radius(15px);
  }

}
@import "defines";

.loadingScreenContent {
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: $whiteColor;
  @include transition-duration(0.5s);
}
@import "../../defines";

.homeCard {
  margin: 15px;
  position: relative;
}

.homeCard::after {
  position: absolute;
  bottom: 0;
  content: '';
  left: 0;
  right: 0;
  z-index: -1;
  height: 0;
  opacity: 0;
  @include border-radius(20px);
  @include transition-duration(.3s);
  background-image: linear-gradient(90deg, $grayOp0p9Color 120px, $grayOp0p3Color 50%);
  background-image: -webkit-linear-gradient(90deg, $grayOp0p9Color 120px, $grayOp0p3Color 50%);
  background-image: -moz-linear-gradient(90deg, $grayOp0p9Color 120px, $grayOp0p3Color 50%);
  @include box-shadow(0 0 2px 1px $grayOp0p3Color);
}

.homeCard:hover::after {
  height: 100%;
  opacity: 1;
}

.homeCardContent {
  margin: 10px;
}

.homeCardTitle {
  margin-bottom: 5px;
}

.homeCardItem {
  margin-top: 5px;
}

.homeCard img {
  @include transition-duration(.3s);
  @include border-radius(50% 50% 20px 20px);
  margin: 0;
  width: 100%;
}

.homeCardContent hr {
  width: 0;
  @include transition-duration(.3s);
}

.homeCard:hover .homeCardContent hr {
  width: 100%;
}

.homeCard:hover img {
  @include border-radius(20px 20px 50% 50%);
}

.writeUsDescriptionBackground {
  @include stock-background("../../images/home/power-cable-background.jpg");
  @include blur-filter(5px);
}

.writeUsDescriptionBackground:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $blackOp0p75Color;
}
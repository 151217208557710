@import "../../defines";

.searchBlock {
  grid-template-columns: auto 170px;
  max-width: 700px;
  margin: auto;
  @include border-radius(5px);
  background-color: $whiteColor;
}

@media only screen and (max-width: 720px), only screen and (max-device-width: 720px),
only screen and (max-height: 720px), only screen and (max-device-height: 720px) {
  .searchBlock {
    grid-template-columns: 100%;
  }
}
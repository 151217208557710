@import "../../defines";
@import "../PopUp";

.photoViewerBlock {
  @extend %popUpContent;
  display: inherit;
  background-image: linear-gradient(15deg, $linGradGray1 50%, $linGradGray2 50%);
  background-image: -webkit-linear-gradient(15deg, $linGradGray1 50%, $linGradGray2 50%);
  background-image: -moz-linear-gradient(15deg, $linGradGray1 50%, $linGradGray2 50%);
}

.photoViewerBlock {
  img {
    margin: auto;
    @include box-shadow(4px 4px 20px $blackColor);
  }
}

.photoViewer::before {
  content: '';
  position: absolute;
  background-color: $blackOp0p75Color;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
}

%photoViewerButton {
  position: absolute;
  z-index: 1;
  top: 50%;
  @include transition-duration(0.3s);
}

.photoViewerPrevious.hide {
  opacity: 0;
  left: -100px;
}

.photoViewerNext.hide {
  opacity: 0;
  right: -100px;
}

.photoViewerPrevious {
  @extend %photoViewerButton;
  left: 0;
}

.photoViewerNext {
  @extend %photoViewerButton;
  right: 0;
}

.photoViewerTitleBlock {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
}

.photoViewerOpenedImg {
  background: no-repeat 50% 50%;
  background-size: contain;
  @include transition-duration(0.3s);
}

.photoViewerImg {
  cursor: pointer;

  @include transition-duration(0.3s);
}

.photoViewerImg:hover {
  @include transform(scale(1.1));
}

@media only screen and (max-width: 720px), only screen and (max-device-width: 720px), only screen and (max-height: 720px), only screen and (max-device-height: 720px) {
  .photoViewerPrevious, .photoViewerNext {
    top: auto;
    bottom: 0;
  }
}